import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  InterviewContentsWrapper,
  InterviewQuestion,
  InterviewHR,
  InterviewP,
  InterviewTalk,
  InterviewAnswer,
  InterviewH2,
  InterviewImg,
} from '~/components/interview/atoms';
import nakaharaImg1 from '~/assets/images/interview/nakahara_img1.jpg';
import nakaharaImg2 from '~/assets/images/interview/nakahara_img2.jpg';
import { useIsJapanese } from '~/hooks';

export const NakaharaContents = () => {
  const isJapanese = useIsJapanese();
  return (
    <InterviewContentsWrapper>
      {isJapanese ? (
        <>
          <InterviewP>
            フリーランスのジェネラリストとしてNHKの実写合成やフルCG映像制作に携わり、カナダのVFXプロダクション「MPC」でデジタルマットペインターとしてハリウッド映画の現場で活躍した中原さん。SAFEHOUSE入社後はリードシネマティックアーティストとして『FRONT
            MISSION Left
            Alive』『仮面ライダーセイバー』など映像、ゲーム作品を手がけてきました。アーティストとして作品に関わる一方で、チームづくりや若手の教育にも注力しています。そんな彼女にグローバルな作品での体験や幼少から影響を受けてきた作品などについて訊きました。
          </InterviewP>
          <InterviewHR />
          <InterviewTalk>
            <InterviewQuestion>自己紹介をお願いします。</InterviewQuestion>
            <InterviewAnswer>
              SAFEHOUSEでシネマティックスーパーバイザーをしている中原です。東京都の出身で、美術大学を卒業した後にフリーランスでCGを始めました。ジェネラリストとしてNHK大河ドラマなどの実写合成やフルCG映像制作などに携わってから、鈴木卓矢（SAFEHOUSE取締役）のチームに参加するようになりました。その後にカナダに渡ってMPCというVFXの会社でデジタルマットペインターとして作品づくりに携わって、今はSAFEHOUSEでUnreal
              Engineを使った映像制作をしています。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              SAFEHOUSEで働くようになったきっかけを教えてください。
            </InterviewQuestion>
            <InterviewAnswer>
              私がフリーランスをしていた頃に当時別会社にいた鈴木（SAFEHOUSE取締役）にお世話になっていたんです。カナダから帰国するタイミングで、鈴木からSAFEHOUSEでシネマティックの日本チームを立ち上げるからと、声をかけてもらったのがきっかけです。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              SAFEHOUSEのシネマティックチームで、中原さんはどのような仕事をされていますか?
            </InterviewQuestion>
            <InterviewAnswer>
              制作のクオリティーやスケジュール管理、若手の教育がメインですが、実作業もしています。他の会社のCGスーパーバイザーに近いこともしてます。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              SAFEHOUSEの良いところはどんなところですか？
            </InterviewQuestion>
            <InterviewAnswer>
              いろいろなチームやプロジェクトで仕事をしてきましたが、キャリアに関係なく意見を言えたり、逆に意見して貰えたりする風通しが良い環境だと思います。アーティストに限らずマネジメントチームも自分の意思で少しでも良いものに仕上げようという人が多いので、良い創作ができています。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            グローバルな作品をつくる時には、仲間のバックグラウンドに合ったコミュニケーションが大切です
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              カナダでお仕事をすることになった経緯を教えてもらえますか？
            </InterviewQuestion>
            <InterviewAnswer>
              CGを始めた頃から「海外で仕事をしました」というキャリアが必要だと思っていたんです。今はそのことがそれほど重要だとは思いませんが、当時は海外に行ってスキルを磨いたり、経験を積んだりしてこようという流れがありました。周りを見ていて、実際に海外でのキャリアが有利に働いている場面をよく見ていました。あとは母親が若い頃にアメリカに住んでいたことも影響しているかもしれません。ただ、仕事が決まるまでは結構大変でした。カナダに渡って半年ぐらい語学学校に通い、１-２か月は求人に応募を続けましたが、全くオファーはなく……。もうカフェのバイトを始めようと思い、面接に受かった帰り道にやっと一社から声がかかりました。
            </InterviewAnswer>
            <InterviewImg
              src={nakaharaImg1}
              caption={'カナダのモントリオールにいた頃に撮影した写真'}
            />
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              海外で仕事をするためにどんな努力をしましたか？
            </InterviewQuestion>
            <InterviewAnswer>
              オファーをもらうにはデモリールがどれだけ充実しているかが重要だと思っていました。私は実写の作品からはじめて、海外でも知られている鈴木のチームに入れてもらったりと、かなり戦略的にキャリアを積んできたと思います。海外で働いている人で「応募したら、なんか受かっちゃいました」って人はあまりいないと思います。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              キャリアの中で最も大変だったことを教えてください。
            </InterviewQuestion>
            <InterviewAnswer>
              ある大規模な作品を複数の海外チームと協力して作ることになったのですが、アーティストとしてだけではなく、管理能力が試されるようなプロジェクトでした。カナダでの経験もあり、SAFEHOUSEではいろいろな国籍・バックグラウンドを持つ人が働いているので、異なる価値観の人と働くのはなれているつもりでしたが、遠く離れた初対面の人と数少ないオンラインミーティングとSlackでのやり取りだけを頼りに、作品を仕上げていくのは骨が折れました。特に世界最高水準の作品を作ることが前提だったため、クオリティや発想、スケジュールにおいてチーム一人ひとりの全力を引き出す必要があったんです。そんな大変な中、チームのみんなが少しでも気持ちよく仕事ができるように取り組みました。物理的にも心理的にも離れた状態から仕事を始めるにあたって、コミュニケーションを円滑にするポイントを2点見つけました。一つ目は「忙しくても仕事以外の無駄話をする」です。お互いどんな人かわからない状態で気持ちよく仕事を始めるのはかなり難しいと思います。仕事と関係のない話をすることで心理的な距離感はグッと縮まるように感じました。二つ目は「笑顔をたやさず、相手が冗談を言った時にはためらわず笑う」です。冗談は相手の国の文化が理解できていないと難しい時もありますが、笑うことで共感を伝えられるし、場を明るくします。
            </InterviewAnswer>
            <InterviewImg src={nakaharaImg2} />
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            小学生の頃から『攻殻機動隊』をコマ送りで見ていました
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              いつからCG映像制作に興味を持ちましたか？
            </InterviewQuestion>
            <InterviewAnswer>
              大学四年生の終わり頃です。美術大学だったので周りは「就職ができなくてどうしよう……」という人ばかりでした。元々はレタッチャーの仕事に興味があったんです。広告で女優さんのシミを消したり、輪郭を整えたりする仕事です。ちょっとミーハーだったので楽しそうだなと思って。ただ、レタッチャーの仕事は極端に少なかったんです。それで、レタッチをしている会社では3DCGの求人が多かったので興味を持ちました。初めは右も左もわからずでしたが、だんだん仕事で認められ、自分の得意分野が分かるようになるとともに、CGの奥深さや面白さもより分かるようになっていきました。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              何が中原さんにインスピレーションを与えますか？
            </InterviewQuestion>
            <InterviewAnswer>
              美術館や映画、漫画、アニメ、本はもちろんですが、ファッションやインテリア、食事、音楽など仕事の範囲外でも興味がたくさんあるんです。それらの色んな経験からインスピレーションを少しずつ受けていると思います。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              シネマティック・アニメーションで最も影響を受けた映画/テレビ番組/ゲームはなんですか？
            </InterviewQuestion>
            <InterviewAnswer>
              一つあげるのはすごく難しいのですが……。小学生のころに見て衝撃的だったのは押井守監督の『GHOST
              IN THE SHELL /
              攻殻機動隊』と今敏監督の『パーフェクトブルー』です。子どもながらに映像の「質」「情報量」に圧倒されていました。あまりに好きで、当時VHSのビデオテープをコマ送りにして見ていたことを覚えています。中学生くらいから漫画家の富沢ひとし先生の作品にもハマり始めました。浪人中は富沢先生の絵を真似て書いたりしていました。大学生のころは撮影監督のコンラッド・L・ホールのライティングとレイアウトが好きで見てました。『ロード・トゥ・パーディション』はレイアウトとライティングが素晴らしいですし『アメリカン・ビューティー』は映画としても好きです。日本人の映画監督だと『家族ゲーム』で有名な森田芳光監督や『東京物語』の小津安二郎監督は「素晴らしいレイアウトを切るな〜」と思います。わりとレイアウトが面白い作品が好きかもしれません。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            基礎を大切に、信頼関係の中でキャリアを築くのが成功への近道です
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              仕事で一番大切にしていることは？
            </InterviewQuestion>
            <InterviewAnswer>
              信頼関係です。学生時代から自分一人で作品を作り上げることに限界を感じていました。私自身は、人と仕事をして大きなものを作り上げる方が向いていると思っています。人と仕事をするにあたっては信頼し合える関係を築けるかどうかが重要だと思います。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              シネマティックアーティストになりたい人へのアドバイスはありますか？
            </InterviewQuestion>
            <InterviewAnswer>
              映像（撮影技法）の基礎教本を3冊くらいは読むのがオススメです。私もそうだったんですが、初心者は有名な映画監督のキャッチーで写真多めの本を読んで分かったつもりになりがちです。一冊読んでもその著者のカラーが強いので偏った知識になってしまいがちです。最低でも3冊読むと共通する重要なことがわかってくると思います。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              インタビューは以上です。最後に現役のシネマティックアーティストや、これから目指す人に向けてメッセージをお願いします。
            </InterviewQuestion>
            <InterviewAnswer>
              AIや技術の進歩によって、一点突破のワザだけで多くのCGアーティストが生きていくのはなかなか難しいのではないかなと最近感じています。これから、映像を自らつくれる力というのはより求められていくと考えていて、SAFEHOUSEのシネマティックアーティストはその力をつけるのに最適な場所だと思っています。色々やりたいことがある人こそ、ぜひ来てもらえたらうれしいです！
            </InterviewAnswer>
          </InterviewTalk>
        </>
      ) : (
        <>
          <InterviewP>
            Satomi Nakahara’s journey began in Japan, working on NHK's
            productions and honing her skills in live-action compositing and CG.
            Interested in growing artistically, she landed a role as a digital
            matte painter at MPC in Canada. Now a lead cinematic artist and
            supervisor at SAFEHOUSE, Satomi's artistry has graced global
            projects like "FRONT MISSION Left Alive" and "Kamen Rider Saber."
            Satomi fosters collaboration and mentors young artists, ensuring the
            future of cinematic magic. Intrigued by her creative influences and
            global experiences, we wanted to know more about her world.
          </InterviewP>
          <InterviewHR />
          <InterviewTalk>
            <InterviewQuestion>
              Hi! Can you introduce yourself?
            </InterviewQuestion>
            <InterviewAnswer>
              My name is Satomi Nakahara, and I'm a Cinematic Supervisor here at
              SAFEHOUSE. Originally from Tokyo, Japan, I started freelancing in
              CG after graduating from an art college. My journey began with
              compositing live-action shots and creating full CG videos for
              projects like the NHK Taiga Drama (Taiga drama is the name NHK
              gives to the annual year-long historical drama television series
              it broadcasts in Japan). When that project finished, I joined
              Takuya Suzuki's (Director of SAFEHOUSE) team. Shortly after, I
              moved to Canada and worked as a digital matte painter for a VFX
              company called MPC, and now I am working at SAFEHOUSE, creating 3D
              cinematic scenes using the Unreal Engine.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              How did you come to work at SAFEHOUSE?
            </InterviewQuestion>
            <InterviewAnswer>
              While freelancing in Canada, I developed a debt of gratitude to
              Takuya Suzuki, who was then at another company. When it was time
              for me to return to Japan, Takuya, knowing I was coming back,
              asked me to join SAFEHOUSE to establish their Japanese cinematic
              team.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What kind of work do you do with SAFEHOUSE's cinematic team?
            </InterviewQuestion>
            <InterviewAnswer>
              My role is multifaceted. I oversee both the production's quality
              and schedule, while also training new talent. Additionally, I take
              on hands-on work, similar to the role of a CG supervisor in other
              companies.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What is the best thing about being at SAFEHOUSE?
            </InterviewQuestion>
            <InterviewAnswer>
              In my experience working across various teams and projects, I've
              found our environment to be truly open. People feel comfortable
              expressing their opinions, regardless of their career level, and
              are even encouraged to do so. This goes beyond just the artists –
              even the management team is willing to roll up their sleeves and
              contribute their ideas. It's this collaborative spirit that
              fosters truly great creative work.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            “When working on global projects, I make a point to communicate
            effectively with my colleagues, considering their cultural
            backgrounds.”
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              Can you tell us how you came to work in Canada?
            </InterviewQuestion>
            <InterviewAnswer>
              Ever since I started in CG, I craved a career that would allow me
              to say, "I worked overseas." Back then, there was a strong
              emphasis on honing skills and gaining experience abroad. Looking
              around, I saw many who benefitted from international careers. My
              own mother's experience living in the U.S. as a young woman might
              have also subconsciously influenced me. The initial job hunt,
              however, proved challenging. After arriving in Canada, I spent six
              months at a language school, followed by another 1-2 months of
              applying for jobs with no success. Just as I considered taking a
              part-time cafe job, a company finally reached out after an
              interview.
            </InterviewAnswer>
            <InterviewImg
              src={nakaharaImg1}
              caption={'Photos taken from Satomi’s time in Montreal, Canada'}
            />
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What effort did you make to work abroad?
            </InterviewQuestion>
            <InterviewAnswer>
              Building a strong demo reel has always been a priority for me,
              knowing it's key to landing opportunities. I believe I've taken a
              strategic approach to my career, starting with live-action
              experience before joining Suzuki's team – a well-respected name
              overseas. Unlike many international careers, mine wasn't a stroke
              of luck. It wasn't a simple case of applying and landing a job
              "somehow."
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What has been the most difficult part of your career?
            </InterviewQuestion>
            <InterviewAnswer>
              I was thrown into the fire when I was assigned to collaborate with
              several international teams on a massive project. It wasn't just
              my artistic skills that were tested, but also my management
              abilities. While working in Canada, I thought I was accustomed to
              working with diverse nationalities and backgrounds. However, this
              project presented a new challenge: collaborating with people I'd
              never met, across vast distances, relying solely on online
              meetings and Slack communication to complete a global project. The
              pressure was immense. To draw out the best in terms of quality,
              ideas, and meeting deadlines from each team member, effective
              communication was paramount. However, I discovered 2 things that
              facilitated communication across physical and cultural barriers:
              <br />
              Casual Conversations Beyond Work: Even if you’re busy, make time
              to banter about non-work-related things. Rapport goes a long way
              in establishing comfort. Small talk helps bridge that
              psychological distance.
              <br />
              Laughter is Universal: Don't be afraid to smile and laugh, even if
              a joke's cultural nuances escape you. Laughter fosters empathy and
              lightens the mood.
            </InterviewAnswer>
            <InterviewImg src={nakaharaImg2} />
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            I have watched "Ghost in the Shell" frame by frame since my
            elementary school days.
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              Were you always interested in cinematic art?
            </InterviewQuestion>
            <InterviewAnswer>
              It was the end of my senior year at art college, and everyone was
              freaking out. "What am I going to do if I can't get a job?" was
              the constant worry. I, however, was initially drawn to retouching
              – the process of smoothing blemishes and sculpting features for
              actresses in ads. It sounded like a fun, detail-oriented job for
              my somewhat introverted self. But the reality was, that retouching
              jobs were scarce. So, I shifted my focus to 3DCG within retouching
              companies, where positions seemed more plentiful. Starting out, I
              was completely lost, unsure of the right or wrong way to do
              things. However, as my work gained recognition, I began to
              understand my strengths and delve deeper into the captivating
              world of CG.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>What inspires you?</InterviewQuestion>
            <InterviewAnswer>
              I have many interests outside of what I do, such as fashion,
              interior design, food, and music, not to mention museums, movies,
              manga, anime, and books. All those various experiences continue to
              inspire me here and there.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What film/TV show/game has had the greatest influence on your
              cinematic animation?
            </InterviewQuestion>
            <InterviewAnswer>
              Pinpointing just one influence is tough… In elementary school,
              Mamoru Oshii's "Ghost in the Shell" and Satoshi Kon's "Perfect
              Blue" completely blew me away. Even as a kid, the sheer quality
              and detail in the animation was staggering. I loved them so much,
              I wore out my VHS tapes rewinding and watching them frame by
              frame. Around junior high, I discovered the manga artist Hitoshi
              Tomizawa. During my ronin year (gap year between high school and
              university), I used to spend hours copying his drawings. In
              university, cinematographer Conrad L. Hall's work on lighting and
              layout really grabbed my attention. "Road to Perdition" has a
              phenomenal layout, and "American Beauty" is a favorite film. As
              for Japanese directors, Yoshimitsu Morita, known for "The Family
              Game," and Yasujiro Ozu, who directed "Tokyo Story," both have an
              amazing sense of layout. I guess I'm drawn to films with visually
              interesting compositions.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            “Building a career on a foundation of trust is the shortcut to
            success.”
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              What do you value most about your job?
            </InterviewQuestion>
            <InterviewAnswer>
              Being able to trust others. Even as a student, I felt limited by
              creating work by myself. I thrive in teamwork, where together we
              can create something truly impactful. Building a foundation of
              mutual trust is key for me in any collaboration.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              Any advice for those who wish to be a cinematic artist?
            </InterviewQuestion>
            <InterviewAnswer>
              I highly recommend diving into at least three foundational
              instructional books on filming techniques. As was the case with
              me, beginners get lured in by those flashy books by famous
              directors, filled with pictures and seemingly easy answers. Even
              if you read one book, it can leave you with a biased understanding
              because of the director's unique style. By tackling at least three
              different books, you'll start to see the core principles that hold
              across the board.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              That's it for now! Do you have a message for our audience or
              anyone wishing to go into the industry?
            </InterviewQuestion>
            <InterviewAnswer>
              With the advancement of AI and technology, I have recently felt
              that it may be very difficult for many CG artists to survive
              relying solely on a single skill set. I believe that the ability
              to create images with one's imagination be in greater demand in
              the future. That's the kind of talent we cultivate here at
              SAFEHOUSE – well-rounded cinematic artists. If you're a passionate
              individual with a thirst to learn and possess a multitude of
              artistic interests, then SAFEHOUSE might just be the perfect place
              for you!
            </InterviewAnswer>
          </InterviewTalk>
        </>
      )}
    </InterviewContentsWrapper>
  );
};
